export enum EmployeeType {
	Counselor = 'Counselor',
	Doctor = 'Doctor',
	Concierge = 'Operator',
	Operator = 'Operator',
	/** Please use Concierge on FE. We have this option because BE uses Operator instead of Concierge. This is necessary for deserialization.*/
	ClinicManager = 'ClinicManager',
	MedicalTeam = 'StaffMember',
	StaffMember = 'StaffMember',
	/** Please use MedicalTeam on FE. We have this option because BE uses StaffMember instead of MedicalTeam. This is necessary for deserialization.*/
	Viewer = 'Viewer',
	Admin = 'Admin'
}
